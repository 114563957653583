.wrap
{
  padding-top: 120px;
}
.navbar
{
  border: 0;
}
.navbar-brand.navbar-logo
{
  line-height: $navbar-height;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 30px;
}
.navbar-brand > img
{
  display: inline-block;
}

@media (min-width: $screen-sm-min) {
  .navbar-brand.navbar-logo
  {
    padding-left: 30px;
  }
  .navbar-right 
  {
    padding-right: 30px;
  }
}

.navbar-nav a
{
  text-transform: uppercase;
  font-weight: 600;
  font-family: $font-family-sans-serif;
}

// Dropdown
@media (min-width: $screen-sm-min) {
  .navbar-nav > .menu-item
  {
    position: relative;
    & > a
    {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
      &:hover
      {
        background-color: #f7f7f7;
      }
    }

    &.menu-item-has-children
    {
      & > a::after
      {
        content: "\f0d7";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        margin-left: .5em;
        margin-right: .07142857em;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
  .navbar-nav .sub-menu
  {
    display: none;
    position: absolute;
    left: 0;
    list-style: none;
    padding: $grid-gutter-width - 10px;
    margin: 0;
    background-color: #f7f7f7;
    z-index: 20;
    font-size: 0;
    min-width: 442px;
    .menu-item
    {
      padding: 10px;
      display: inline-block;
      width: 50%;
    }
    a
    {
      font-weight: 400;
      color: #343434;
      font-size: 17px;
      text-transform: none;
    }
  }
  .navbar-nav .menu-item:hover .sub-menu
  {
    display: block;
  }
}
@media (max-width: 1155px) {
  .navbar-text
  {
    display: none;
  }
}

.navbar-text {
  margin-top: 40px;
  margin-bottom: 40px;
}
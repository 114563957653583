.page-banner
{
  min-height: 457px;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  background-color: #181014;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;

  .inner-container
  {
    padding: 0 $grid-gutter-width/2;
  }
  
  .btn-primary
  {
    box-shadow: 1px 1px 1px rgba(0,0,0,.77);
  }
}

.banner-phone {
  color: white !important;
  font-size: 18px;
  margin-top: 20px;
  font-family: Raleway,sans-serif;


  // deal with phones marking phone numbers as links
  a {
    color: white !important;
  }
}

.banner-title
{
  color: #FFF;
  font-family: $font-family-serif-alt;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  line-height: 1.25em;
  margin-top: 0;
  margin-bottom: 36px;
  position: relative;
  text-shadow: 1px 1px 2px rgba(0,0,0,.13);

  &::after
  {
    display: block;
    position: absolute;
    bottom: -15px;
    left: 50%;
    content: '';
    $separator-width: 36px;
    width: $separator-width;
    margin-left: -($separator-width/2);
    border-bottom: 2px solid rgba(255,255,255,.2);
  }
}
.banner-copy
{
  color: #FFF;
  text-transform: uppercase;
  font-family: $font-family-sans-serif;
  font-weight: 600;
  letter-spacing: 0.4em;
  margin-bottom: 3em;
  text-shadow: 1px 1px 1px rgba(0,0,0,.26);

  .home &
  {
    color: #f1d2db;
  }

  p:last-child
  {
    margin-bottom: 0;
  }
}
@media (max-width: $screen-xs-max) {
  .page-banner{
    font-size: 13px;
    h1
    {
      font-size: 27px;
    }
  }
}
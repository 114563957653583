// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #a12d2d;
// $brand-success:         #5cb85c !default;
// $brand-info:            #5bc0de !default;
// $brand-warning:         #f0ad4e !default;
// $brand-danger:          #d9534f !default;
// 
// Typography
$font-family-sans-serif:  'Raleway', sans-serif;
$font-family-serif:       Georgia, 'Times New Roman', Times, serif;
$font-family-serif-alt:   'Cardo', serif;
$font-family-base:        $font-family-serif;
$headings-font-family:    'Raleway', sans-serif;
// $headings-font-weight:    700;
// $headings-line-height:    1.1;
// $headings-color:          inherit;

//=== Shared nav styles
// $nav-link-padding:                          0;
// $nav-link-hover-bg:                         transparent;
$navbar-height: 120px;
$navbar-default-link-color: #181114;
$navbar-border-radius: 0;
$navbar-default-bg: #FFF;
$navbar-margin-bottom: 0;


$border-radius-base: 0;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                700;

$btn-default-color:              #fff;
$btn-default-bg:                 transparent;
$btn-default-border:             #fff;

$btn-primary-border:             #8d1627;


$input-border: #eae2e6;

$gray-lighter: #f7f7f7;
$navbar-default-link-active-bg: #f7f7f7;

$grid-float-breakpoint: 936px;

.membership-logos
{
  padding-top: 50px;
  padding-bottom: 70px;
  text-align: center;
}
.membership-logo-container
{
  height: 75px;
}
.membership-title
{
  display: block;
  color: #7c7377;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.17em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-top: 2em;
}
@media (max-width: $screen-sm-min - 1) {
  .membership-logos .row > div
  {
    margin-top: $grid-gutter-width*2;
    
    &:first-child
    {
      margin-top: 0;
    }
  }
}
.main-footer
{
  background-color: #181114;
  padding-top: 3em;
  padding-bottom: 2em;

  ul.main-footer-menu
  {
    list-style: none;
    padding: 0;
    margin-bottom: 60px;
  }

  ul.main-footer-menu > .menu-item > a
  {
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 0.1em;
    line-height: 3em;
  }
  .sub-menu
  {
    list-style: none;
    padding: 0;

    .menu-item
    {
      font-size: 14px;
      line-height: 1.71em;
    }
    a
    {
      font-family: $font-family-serif;
      font-weight: 400; 
      color: #7b7a7a;   
    }
  }

  .copyright-text
  {
    color: #676767;
    text-align: center;
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 0.86em;
    letter-spacing: 0.4em;
  }
}

@media (max-width: $screen-sm-max) {
  ul.main-footer-menu
  {
    text-align: center;
  }
}

@media (min-width: $screen-md-min) {
  ul.main-footer-menu
  {
    & > .menu-item
    {
      text-align: right;
      &:first-child
      {
        text-align: left;
      }
    }
    .sub-menu
    {
      font-size: 0;
      .menu-item
      {      
        width: 50%;
        display: inline-block;
        &::before
        {
          content: '· ';
          display: inline;
          color: #FFF;
        }
      }
    }
  }
}
.content-rows
{
  & > div:nth-child(odd)
  {
    background-color: #f7f7f7;
    .contact-us &
    {
      background-color: #fff;
    }
  }
  & > div:nth-child(even)
  {
    background-color: #fff;
    .contact-us &
    {
      background-color: #f7f7f7;
    }
  }
}
.content-row-text-and-image,
.content-row-text,
.content-row-contact-form,
.content-row-quick-contact
{
  padding-top: $grid-gutter-width * 2;
  padding-bottom: $grid-gutter-width * 2;
}
@media (min-width: $screen-md-min) {
  .content-row-text-and-image
  {
    padding-top: 96px;
    padding-bottom: 96px;
    .row
    {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      -ms-flex-align: center;
      -webkit-align-items: center;
      -webkit-box-align: center;

      align-items: center;
    }
  }
  .content-row-text
  {
    padding-top: 86px;
    padding-bottom: 86px;
  }
}
.content-row-copy
{
  text-align: center;
  color: #7c7377;
  h2
  {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    color: #181114;
    margin-bottom: 2em;

    &::after
    {
      display: block;
      position: absolute;
      bottom: -21px;
      left: 50%;
      content: '';
      $separator-width: 28px;
      width: $separator-width;
      margin-left: -($separator-width/2);
      border-bottom: 2px solid $btn-primary-border;
    }

    &:first-child
    {
      margin-top: 10px;
    }
  }
  h3
  {
    font-family: $font-family-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.33em;
    margin-bottom: 20px;

    a
    {
      color: #4f4b4d;
      text-decoration: underline;
    }
  }
  p
  {
    font-size: 17px;
  }
  .btn-block
  {
    margin: 20px 0 10px;
  }
}
.content-row-gallery-wrapper
{
  padding: 3px;
  border: 1px solid $btn-primary-border;
}
.content-row-gallery
{
  border: 3px solid $btn-primary-border;

  img
  {
    border: none;
    outline: none;
  }
}

.link-boxes
{
  margin-top: $grid-gutter-width;
}
.link-box-wrapper
{
  padding: 3px;
  border: 1px solid $btn-primary-border;
}
.link-box
{
  border: 3px solid $btn-primary-border;
  text-align: center;
  padding: 50px $grid-gutter-width;
}
h3.link-box-title
{
  position: relative;
  font-family: $font-family-serif-alt;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.27em;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  margin-top: 0;
  margin-bottom: 40px;
  &::after
  {
    display: block;
    position: absolute;
    bottom: -21px;
    left: 50%;
    content: '';
    $separator-width: 28px;
    width: $separator-width;
    margin-left: -($separator-width/2);
    border-bottom: 2px solid $btn-primary-border;
  }
}
.link-box-link
{
  font-family: $font-family-sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.3em;
}
@media (max-width: $screen-xs-max) {
  .link-box-wrapper
  {
    margin-top: $grid-gutter-width;
  }
}
@media (max-width: $screen-sm-max) {
  h3.link-box-title
  {
    font-size: 18px;
    letter-spacing: 0.18em;
  }
  .link-box-link
  {
    font-size: 12px;
  }
}
@media (max-width: $screen-md-max) {
  .link-box
  {
    padding: $grid-gutter-width 10px;
  }
}

.content-row-testimonials
{
  padding: 100px 0 75px;
}
.testimonial
{
  background-color: #ffffff;
  border-bottom: 3px solid $btn-primary-border;
  box-shadow: 1px 1px 2px rgba(0,0,0,.77);
  padding: $grid-gutter-width*1.5 $grid-gutter-width;
  text-align: center;
  color: #7c7377;
  font-size: 17px;
  line-height: 1.41em;

  margin-top: 50px;
  &:first-child
  {
    margin-top: 0;
  }

  .testimonial-author
  {
    display: block;
    margin-top: 1em;
    font-size: 18px;
  }
}
.content-row-locations
{
  padding-bottom: 80px;

  .content-row-copy
  {
    padding: 50px 0;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 1em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #3e3c3d;

    p
    {
      font-size: 24px;
      line-height: 1em;
    }
  }
}
.location-wrapper
{
  background-color: #ffffff;
  border-bottom: 3px solid $btn-primary-border;
  box-shadow: 1px 1px 2px rgba(0,0,0,.77);
  text-align: center;
  color: #7c7377;
  margin-bottom: 34px;
  .location-map
  {
    width: 100%;
    height: 235px;
  }
  .location-copy
  {
    padding: $grid-gutter-width/2;
    font-size: 17px;
    line-height: 1.41em;
  }
  .location-title
  {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    color: #181114;
    margin-bottom: 2em;

    &::after
    {
      display: block;
      position: absolute;
      bottom: -21px;
      left: 50%;
      content: '';
      $separator-width: 28px;
      width: $separator-width;
      margin-left: -($separator-width/2);
      border-bottom: 2px solid $btn-primary-border;
    }
  }

  h3
  {
    font-family: $font-family-serif;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
  }
}

@media (max-width: $screen-xs-max) {
  .location-rows > div
  {
    margin-top: $grid-gutter-width*2;
    &:first-child
    {
      margin-top: 0;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .location-wrapper
  {
    min-height: 560px;
  }
}

@media (min-width: $screen-md-min) {
  .location-rows > div
  {
    &:nth-child(odd)
    {
      .location
      {        
        margin-left: 63px;
        margin-right: 40px;
      }
    }
    &:nth-child(even)
    {
      .location
      {
        margin-left: 40px;
        margin-right: 63px;
      }
    }
  }
}

::-webkit-input-placeholder {
   font-style: italic;
}

:-moz-placeholder { /* Firefox 18- */ 
   font-style: italic;
}

::-moz-placeholder {  /* Firefox 19+ */
   font-style: italic;
}

:-ms-input-placeholder {   
   font-style: italic;
}

.contact-form-container
{
  background-color: #fafafa;
  border: 1px solid $btn-primary-border;
  padding: $grid-gutter-width $grid-gutter-width 0;
  
  label
  {
    color: $brand-primary;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-family: $font-family-sans-serif;
    font-weight: 700;
  }
  textarea
  {
    height: 160px;
    resize: none;
  }
  .form-group
  {
    margin-bottom: 24px;
  }


}


@media (min-width: $screen-md-min) {
  .content-row-contact-form .contact-form-container
  {
    margin-left: auto;
    margin-right: auto;
    width: 750px;
  }
}

.quick-contact-copy h2
{
  position: relative;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  color: #181114;
  margin-bottom: 2em;
  margin-top: 0;

  &::after
  {
    display: block;
    position: absolute;
    bottom: -21px;
    left: 0;
    content: '';
    $separator-width: 28px;
    width: $separator-width;
    border-bottom: 2px solid $btn-primary-border;
  }
}

.quick-contact-location
{
  margin-top: 1.41em;
  font-size: 17px;
  line-height: 1.59em;

  .gmaps-link
  {
    font-size: 14px;
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    font-weight: 700;
  }
  .footnote
  {
    font-size: 14px;
  }
}